import * as React from 'react'
import Layout from 'components/Layout/Layout'
import Seo from 'components/Layout/Seo'
import { DisclaimerSlice } from 'components/Slices'

const DocumentationPage = () => (
  <Layout lightHeader>
    <Seo title="Legal Disclaimer" pathname="disclaimer" />
    <DisclaimerSlice />
  </Layout>
)

export default DocumentationPage
